import React from 'react';
import Layout from "../components/layout";
import { Link } from "gatsby";
import {Button} from 'react-bootstrap';

const Thankyou = () => {
    return  (
        <Layout>
            <div className="container">
                <div className="row">
                    <div className="col-md-6">
                        <div className="thankyou-image-wrapper">
                        <iframe title="Crampete" width="560" height="315" src="https://www.youtube.com/embed/PSmy_qlgTI4" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                        </div>
                    </div>
                    <div className="col-md-6">
                        <div className="thankyou-content ">
                            <h3>Thank you!</h3>
                            <p>Thanks a bunch for filling that out. It means a lot to us, just like you do! We really appreciate you giving us a moment of your time today. Thanks for being you.</p>
                            <Link to="/"><Button>Back to home</Button></Link>
                        </div>
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export default Thankyou